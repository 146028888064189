<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ sidebarTitle }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideSideBar()"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group label="Title " invalid-feedback="Title is required.">
              <b-form-input
                placeholder="Enter tile."
                ref="title"
                v-model="myObj.title"
                @focusout="CheckTitle()"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Message"
              invalid-feedback="Messgae is required."
              ref="msg"
            >
              <b-form-textarea
                ref="msg"
                placeholder="Enter message."
                v-model="myObj.message"
                @focusout="CheckMessage()"
                rows="5"
              ></b-form-textarea>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              class="mr-1 fullWidth"
              @click="Add()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-if="request == false"> {{ sidebarButton }} </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-card>
      <!-- <b-row>
          <b-col md="7">
            <b-form-group label="Title " invalid-feedback="Title is required.">
              <b-form-input
                placeholder="Enter tile."
                ref="title"
                v-model="myObj.title"
                @focusout="CheckTitle()"
              />
            </b-form-group>
  
            <b-form-group
              label="Message"
              invalid-feedback="Messgae is required."
              ref="msg"
            >
              <b-form-textarea
                ref="msg"
                placeholder="Enter message."
                v-model="myObj.message"
                @focusout="CheckMessage()"
                rows="5"
              ></b-form-textarea>
            </b-form-group>
  
            <div class="text-right">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                :disabled="request"
                @click="Add()"
              >
                <b-spinner v-if="request" small type="grow" />
                <span v-if="request == false"> Save </span>
              </b-button>
            </div>
          </b-col>
          <b-col md="5">
            <b-row>
              <b-col md="12">
                <b-input-group class="input-group-merge w-100">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" class="text-muted" />
                  </b-input-group-prepend>
                  <b-form-input v-model="searchQuery" placeholder="Search..." />
                </b-input-group>
              </b-col>
  
              <b-col
                md="12"
                class="mt-1"
                style="display: flex; cursor: context-menu"
                v-for="item in items"
                :key="item.id"
                @click="Edit(item.id)"
              >
                <b-avatar class="mb-1" variant="light-primary" size="35">
                  <span style="font-size: 20px">{{
                    item.title.substring(0, 1)
                  }}</span>
                </b-avatar>
                <div class="" style="margin-left: 5px">
                  <h4 class="font-weight-bolder mb-0" style="margin-top: 5px">
                    {{ item.title.substring(1) }}
                  </h4>
                  <p style="max-height: 1.5em; overflow: hidden">
                    {{ item.message }}
                  </p>
                </div>
              </b-col>
          
            </b-row>
          </b-col>
        </b-row> -->

      <b-row class="mt-1">
        <b-col
          class="mt-lg-0 mt-md-0 mt-sm-1 mt-1"
          xl="6"
          lg="5"
          md="4"
          sm="12"
          cols="12"
        >
          <b-form-group class="">
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input v-model="searchQuery" placeholder="Search...">
              </b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col xl="2" lg="3" md="3" sm="12" class="mb-1 mb-md-0">
          <v-select
            v-model="currentStatus"
            :disabled="dataLoading || deleting"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="statusOptions"
            :clearable="false"
            label="text"
            :reduce="(val) => val.value"
            @input="LoadData()"
            placeholder="Select status"
          />
        </b-col>
        <b-col
          xl="4"
          lg="4"
          md="5"
          sm="12"
          cols="12"
          class="d-flex align-items-start"
          style="gap: 1rem"
        >
          <b-button
            @click="LoadData()"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            :disabled="dataLoading || deleting"
            block
          >
            <feather-icon icon="RefreshCcwIcon" class="mr-50" />
            <span class="align-middle"
              >Refresh
              {{ currentStatus != "challan" ? `(${items.length})` : "" }}</span
            >
          </b-button>
          <!--  && currentStatus != 'challan' -->
          <b-button
            v-if="rights.delete"
            @click="deleteAll()"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-danger"
            class="btn-icon"
            :disabled="dataLoading || deleting"
            v-b-tooltip.hover.top
            :title="
              currentStatus == 'draft'
                ? `Clear Draft (${items.length})`
                : currentStatus == 'challan'
                ? `Clear Challan (${items.length})`
                : `Clear Outbox (${items.length})`
            "
          >
            <feather-icon icon="Trash2Icon" />
            <!-- <span class="align-middle">({{ items.length }})</span> -->
          </b-button>
          <!-- @click="currentStatus == 'challan' ? sendChallan() : sendDraft()" -->
          <b-button
            v-if="['draft'].includes(currentStatus) && items.length > 0"
            @click="sendDraft()"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="btn-icon"
            :disabled="dataLoading || deleting"
            v-b-tooltip.hover.top
            title="Start Sending Messages"
          >
            <feather-icon icon="MailIcon" />
          </b-button>
          <!-- <template v-if="pauseObj">
            <b-button
              v-if="pauseObj.valueBool"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              :disabled="dataLoading || deleting || pausing"
              v-b-tooltip.hover.top
              title="Resume WhatsApp"
              @click="print()"
            >
              <feather-icon icon="PlayIcon" />
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              :disabled="dataLoading || deleting || pausing"
              v-b-tooltip.hover.top
              title="Pause WhatsApp"
            >
              <feather-icon icon="PauseIcon" />
            </b-button>
          </template> -->
        </b-col>

        <!-- <b-col xl="2" lg="3" md="4" sm="12" cols="12" v-if="rights.delete">
          <b-button
            @click="deleteAll()"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
            :disabled="deleting"
          >
            <span class="align-middle">Delete All ({{ items.length }})</span>
          </b-button>
        </b-col> -->
      </b-row>

      <b-row v-if="currentStatus == 'challan'" class="hover-card mt-1">
        <b-col md="3">
          <b-card bg-variant="primary" text-variant="white" class="">
            <h4 class="text-white font-weight-bolder">Total</h4>
            <p class="font-weight-bolder mb-0 text-right summary-count">
              {{ summaryObj.total }}
            </p>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card bg-variant="success" text-variant="white" class="">
            <h4 class="text-white font-weight-bolder">Sent</h4>
            <p class="font-weight-bolder mb-0 text-right summary-count">
              {{ summaryObj.sent }}
            </p>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card bg-variant="warning" text-variant="white" class="">
            <h4 class="text-white font-weight-bolder">In Process</h4>
            <p class="font-weight-bolder mb-0 text-right summary-count">
              {{ summaryObj.pending }}
            </p>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card bg-variant="danger" text-variant="white" class="">
            <h4 class="text-white font-weight-bolder">Not on WhatsApp</h4>
            <p class="font-weight-bolder mb-0 text-right summary-count">
              {{ summaryObj.error }}
            </p>
          </b-card>
        </b-col>
      </b-row>

      <b-table
        class="mt-1"
        ref="selectableTable"
        :items="filters"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :busy="dataLoading"
        show-empty
        responsive
        hover
      >
        <template #empty="scope">
          <h3 class="mt-1 mb-1" style="text-align: center">No records found</h3>
        </template>
        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>

        <template #head(actions)="data">
          <div class="mr-5 text-right">
            <span>{{ data.label }}</span>
          </div>
        </template>

        <template #cell(meta_avatar)="data">
          <b-img
            v-if="data.value !== null && data.value !== ''"
            rounded
            :src="data.value"
            height="80px"
            width="80px"
            alt=""
          />
          <b-img
            v-else
            src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
            height="80px"
            width="80px"
          />
        </template>

        <template #cell(date)="data">
          <b-badge variant="light-primary">
            {{
              new Date(data.item.date).toLocaleDateString("en-UK", {
                year: "numeric",
                day: "numeric",
                month: "short",
              })
            }}
          </b-badge>
        </template>

        <template #cell(actions)="data">
          <b-button
            v-if="rights.delete"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger"
            v-b-tooltip.hover.right
            title="Delete"
            class="btn-icon"
            @click="Delete(data.item.id)"
          >
            <feather-icon icon="Trash2Icon" />
          </b-button>
        </template>
      </b-table>
      <b-col cols="12" class="d-flex align-items-center justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="filters.length"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-col>
    </b-card>
  </div>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BMediaBody,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
  BFormTextarea,
  BAvatar,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { forEach } from "postcss-rtl/lib/affected-props";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    //Multiselect,
    BAvatar,
    BFormTextarea,
    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    // BFormCheckbox,
    BForm,
    BPagination,
    BSpinner,
    BImg,
    BMedia,
    BMediaAside,
    BMediaBody,
    BDropdown,
    BDropdownItem,

    vSelect,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    if (!this.$store.state.rights[this.$route.name]) {
      // console.log(this.$store.state.rights[this.$route.name]);
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];
      // console.log(this.rights);
      this.LoadData();
      // this.LoadSetting();
    }
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),
    filters: function () {
      return this.items.filter((pro) => {
        return (
          pro.meta_title.toLowerCase().match(this.searchQuery.toLowerCase()) ||
          pro.message.toLowerCase().match(this.searchQuery.toLowerCase())
        );
      });
    },
  },
  data() {
    return {
      rights: {},
      titleText: "This is title",
      myDepartments: [],
      request: false,
      colorDep: [],
      visibility1: false,
      color: [
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
      ],

      sidebarTitle: "Add Template",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,
      deleting: false,
      visibility: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      totalRows: 0,
      currentStatus: "pending",
      statusOptions: [
        { text: "Pending", value: "pending" },
        { text: "Draft", value: "draft" },
        { text: "Challan", value: "challan" },
      ],
      errors: {
        status: false,
      },

      index: null,

      fields: [
        { label: "profile", key: "meta_avatar" },
        { label: "name", key: "meta_title" },
        { label: "date", key: "date" },
        { label: "message", key: "message" },
        { label: "number", key: "number" },
        { key: "actions", label: "actions" },
      ],
      items: [],
      selected: null,
      rangeDate: null,
      myObj: {
        id: 0,
        title: "",
        message: "",
        campusID: this.$store.state.userData.cId,
      },
      detailObj: {},
      timeout: 10000,
      pauseObj: null,
      pausing: false,
      summaryObj: {
        error: 0,
        pending: 0,
        sent: 0,
        total: 0,
      },
    };
  },
  validations: {
    age: {
      between: between(20, 30),
    },
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    async LoadSetting() {
      let res = await this.post({
        url:
          this.$store.state.domain +
          "Settings/getSettings?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        body: ["whatsapp_pause"],
        context: this,
        token: this.$store.state.userData.token,
        subjects: true,
      });

      let obj = res.find((el) => el.key == "whatsapp_pause");
      if (!obj) {
        this.pauseObj = {
          id: 0,
          campusID: this.$store.state.userData.cId,
          key: "whatsapp_pause",
          value: "whatsapp_pause",
          valueBool: false,
          valueNumber: null,
        };
      } else this.pauseObj = obj;
    },
    async saveSetting() {
      let obj = { ...this.pauseObj };
      obj.valueBool = !obj.valueBool;
      let msg = `WhatsApp ${
        obj.valueBool ? "paused" : "resumed"
      } updated successfully.`;

      let result = await Swal.fire({
        title: "Are you sure?",
        text: `Do you want to ${obj.valueBool ? "pause" : "resume"} WhatsApp?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes",
      });
      if (result.isConfirmed) {
        this.pausing = true;

        var status = await this.post({
          url:
            this.$store.state.domain +
            "Settings/SaveNew?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: [obj],
          message: msg,
          context: this,
          token: this.$store.state.userData.token,
        });
        this.pausing = false;
        if (status) {
          this.LoadSetting();
        }
      }
    },

    async Edit(id) {
      var obj = {
        url:
          this.$store.state.domain +
          "MessageTemplate/GetSelected?id=" +
          id +
          "&db=" +
          this.$store.state.userData.db,
        token: this.$store.state.userData.token,
      };
      this.myObj = await this.get(obj);
      // console.log("editObj:", this.myObj);

      var elem = this.$refs["title"];
      elem.state = undefined;
      var elem = this.$refs["msg"];
      elem.state = undefined;

      this.sidebarTitle = "Edit Template";
      this.sidebarButton = "Update";
      this.visibility = true;
    },

    AddOpen() {
      this.myObj = {
        id: 0,
        title: "",
        message: "",
        campusID: this.$store.state.userData.cId,
      };
      var elem = this.$refs["title"];
      elem.state = undefined;
      var elem = this.$refs["msg"];
      elem.state = undefined;

      this.sidebarTitle = "Add Template";
      this.sidebarButton = "Save";
      this.visibility = true;
    },

    CheckTitle() {
      var elem = this.$refs["title"];
      if (this.myObj.title == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckMessage() {
      var elem = this.$refs["msg"];
      if (this.myObj.message == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },

    hideSideBar() {
      this.visibility = false;
    },

    async LoadData() {
      let endpoint = "Chat/LoadOutBox";
      if (this.currentStatus == "draft") endpoint = "Chat/LoadDrafts";
      else if (this.currentStatus == "challan") endpoint = "Chat/LoadChallan";

      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          endpoint +
          "?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
        dashboard: true,
      };
      let res = await this.get(obj);
      this.items = res.data;
      if (this.currentStatus == "challan") {
        this.summaryObj = {
          error: res.error,
          pending: res.pending,
          sent: res.sent,
          total: res.total,
        };
      }
      this.dataLoading = false;
      // console.log("items", this.items);
    },

    async Add() {
      this.CheckTitle();
      this.CheckMessage();
      if (this.CheckTitle() == false || this.CheckMessage() == false) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        if (this.myObj.id == 0) {
          //Add
          this.request = true;
          // console.log("obj:", this.myObj);
          var status = await this.post({
            url:
              this.$store.state.domain +
              "MessageTemplate?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Template added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          if (status) this.LoadData();
          this.visibility = false;
        } else {
          //Edit
          this.request = true;
          // console.log("edit_obj:", this.myObj);
          var status = await this.put({
            url:
              this.$store.state.domain +
              "MessageTemplate/" +
              this.myObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Template updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          if (status) this.LoadData();
          this.visibility = false;
        }
      }
    },

    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });
      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "Chat/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          message: "Message removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        // console.log(status);
        if (status) {
          let ind = this.items.findIndex((el) => el.id == id);
          this.items.splice(ind, 1);
        }
      }
    },

    async deleteAll() {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: `Do You want to Clear ${
          this.currentStatus == "draft"
            ? "Draft"
            : this.currentStatus == "challan"
            ? "Challan"
            : "Outbox"
        } Messages?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes",
      });
      if (result.isConfirmed) {
        this.deleting = true;

        let endpoint = "Chat/ClearOutBox";
        let msg = "Outbox cleared!";
        if (this.currentStatus == "draft") {
          endpoint = "Chat/ClearDrafts";
          msg = "Draft Cleared!";
        } else if (this.currentStatus == "challan") {
          endpoint = "Chat/ClearChallan";
          msg = "Challan Cleared!";
        }
        // console.log(endpoint, msg);

        var status = await this.post({
          url:
            this.$store.state.domain +
            endpoint +
            "?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: "",
          message: msg,
          context: this,
          token: this.$store.state.userData.token,
        });
        this.deleting = false;
        if (status) this.LoadData();
      }
    },
    async sendDraft() {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "Do You want start sending draft messages?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes",
      });
      if (result.isConfirmed) {
        this.deleting = true;

        var status = await this.get({
          url:
            this.$store.state.domain +
            "Chat/StartSendingDraft?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          message: "Messages are being processed",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.deleting = false;
        this.LoadData();
      }
    },
    async sendChallan() {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "Do You want start sending challan messages?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes",
      });
      if (result.isConfirmed) {
        this.deleting = true;

        var status = await this.get({
          url:
            this.$store.state.domain +
            "Chat/StartSendingChallan?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          message: "Messages are being processed",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.deleting = false;
        this.LoadData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.card .card {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
  margin-bottom: 0.75rem;
}
</style>
